import { LocalizedString } from '@/models/LocalizedString'

function cleanLocale(locale: string) {
  return locale.split('-')[0]
}

export function getLabelFromLocalizedString(value: LocalizedString, locale: string, fallbackLocale: string) {
  if (typeof value === 'string') {
    return value
  }

  const cleanedLocale = cleanLocale(locale)
  const cleanedFallbackLocale = cleanLocale(fallbackLocale)

  if (value[cleanedLocale]) {
    return value[cleanedLocale]
  } else if (value[cleanedFallbackLocale]) {
    return value[cleanedFallbackLocale]
  } else {
    return value[Object.keys(value)[0]]
  }
}
