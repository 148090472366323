{
  "emmyTalk": {
    "messagesTab": {
      "title": "Nachrichten"
    },
    "candidatesTab": {
      "title": "Kandidat:innen"
    },
    "projectsTab": {
      "title": "Projekte"
    },
    "search": {
      "active": "Aktiv",
      "inActive": "Inaktiv"
    },
    "send": {
      "placeholder": "Hier antworten...",
      "sendButton": "Senden"
    },
    "project": "Projekt"
  },
  "home": {
    "greeting": "Hallo ",
    "greetingMorning": "Guten Morgen ",
    "greetingDay": "Guten Tag ",
    "greetingEvening": "Guten Abend ",
    "lastCandidates": "Letzten 5 Kandidat:innen",
    "candidateInterviews": "Interview Kandidat:innen",
    "noInterviewPlanned": "Keine Interviews geplant",
    "project": "Projekt",
    "interview": "Interview",
    "marketOverview": "Marktübersicht (Vorschau)",
    "pastInterview": "Vergangene Interviews",
    "futureInterview": "Zukünftige Interviews"
  },
  "report": {
    "bannerMessages": {
      "loading": "Lade Report",
      "maintenance": "Report wird gerade gewartet. Bitte versuche es später erneut.",
      "lagecy": "Verwende Lagecy Authentifizeriung",
      "error": "Fehler beim Laden des Reports",
      "unauthorized": "Du bist nicht berechtigt den Report zu sehen"
    }
  },
  "projects": {},
  "project": {
    "details": "Details",
    "consultant": "Berater:innen",
    "quickView": {
      "title": "Projekt - QuickView",
      "contacted": "Kontaktiert",
      "feedbackPending": "Feedback ausstehend",
      "inCommunication": "Aktuell im Austausch",
      "interview": "Auswahlprozess",
      "inDfindProcess": "Im Prozess",
      "customerProcess": "Im Kundenprozess",
      "rejection": "Absagen | Absage | Absagen",
      "total": "Gesamte Kandidaten:innen"
    },
    "information": {
      "title": "Projekt - Information",
      "current": "Aktuell",
      "profilePresentation": "Vorstellung",
      "firstInterviewPhase": "Erste Interviewphase",
      "secondInterviewPhase": "Zweite Interviewphase",
      "contractPhase": "Vertragsverhandlungen",
      "total": "Gesamt",
      "rejectionAfterPresentation": "Keine Absage nach Profilvorstellung | Absage nach Profilvorstellung | Absagen nach Profilvorstellung",
      "invitationFirstInterview": "Keine Einladungen zum ersten Kundeninterview | Einladung zum ersten Kundeninterview | Einladungen zum ersten Kundeninterview",
      "rejectionAfterFirstInterview": "Keine Absage nach erstem Interview | Absage nach erstem Interview | Absagen nach erstem Interview",
      "invitationFollowOnInterview": "Keine Einladung zu Folgeinterview(s) | Einladung zu Folgeinterview(s) | Einladungen zu Folgeinterview(s)",
      "rejectionAfterFollowOnInterview": "Keine Absage nach Folgeinterview(s) | Absage nach Folgeinterview(s) | Absagen nach Folgeinterview(s)",
      "numberOffers": "Anzahl Vertragsangebote",
      "declinedOffers": "Keine Absagen | Absage | Absagen",
      "profilePresentationCurrent": "Profilvorstellung - Aktuell",
      "firstInterviewPhaseCurrent": "Erste Interviewphase",
      "secondInterviewPhaseCurrent": "Zweite Interviewphase",
      "contractNegotiationsCurrent": "Vertragsverhandlungen - Aktuell",
      "interviewPhase": "Interview Phase"
    },
    "reportTab": {
      "segmentTitle": "Report"
    },
    "candidatesTab": {
      "segmentTitle": "Kandidat:innen"
    },
    "jobDetailsTab": {
      "segmentTitle": "Jobdetails",
      "overview": {
        "title": "Übersicht",
        "status": "Status",
        "jobStartDate": "Job Startdatum",
        "salary": "Gehalt",
        "location": "Ort"
      },
      "searchProfile": {
        "title": "Suchprofil",
        "jobDescription": "Jobbeschreibung",
        "noJobDescription": "Keine Jobbeschreibung vorhanden",
        "koQuestions": "K.O.-Fragen",
        "noKoQuestions": "Keine K.O.-Fragen verfügbar",
        "departmentProfile": "Abteilungsprofil",
        "noDepartmentProfile": "Kein Abteilungsprofil vorhanden"
      },
      "projectTeam": {
        "title": "Projektteam",
        "contactHr": "Ansprechpartner:innen HR",
        "consultant": "Berater:innen",
        "contactField": "Ansprechpartner:in Fachbereich",
        "furtherConsultants": "Weitere Berater:innen"
      }
    },
    "documentsTab": {
      "segmentTitle": "Dokumente",
      "title": "Notizen & Anlagen",
      "noDocuments": "Keine Dokumente verfügbar",
      "fileTitle": "Titel",
      "fileFormat": "Dateiformat",
      "fileType": "Dokumententyp",
      "fileDownload": "Herunterladen",
      "cv": "Lebenslauf",
      "projectList": "Projektliste",
      "report": "Report",
      "certificate": "Zertifikat",
      "misc": "Diverses",
      "binaryFormat": "Binär",
      "imageFormat": "Bild",
      "xing": "Xing",
      "applicationPackage": "Bewerbungsunterlagen",
      "coverLetter": "Anschreiben",
      "grades": "Noten",
      "github": "GitHub",
      "linkedin": "LinkedIn",
      "other": "Sonstige",
      "referenceLetter": "Arbeitszeugnis"
    },
    "historyTab": {
      "segmentTitle": "Projekthistorie"
    },
    "notFound": "Projekt nicht gefunden oder nicht authentifiziert zu sehen"
  },
  "candidates": {
    "details": "Details",
    "toggleActiveCandidates": "Zeigt aktive Kandidat:innen",
    "toggleInActiveCandidates": "Zeigt inaktive Kandidat:innen"
  },
  "candidate": {
    "project": "Projekt",
    "previousCandidate": "Vorheriger",
    "nextCandidate": "Nächster",
    "rejectionReason": "Absagegrund",
    "nextInterview": "Nächstes Interview am:",
    "nextFollowUpInterview": "Folgeinterview am:",
    "documentsTab": {
      "segmentTitle": "Dokumente",
      "cv": "CV",
      "projectList": "Projektliste",
      "report": "Report",
      "certificate": "Zertifikat",
      "misc": "Anderes",
      "imageFormat": "Foto",
      "other": "Anderes",
      "photo": "Foto",
      "logo": "Logo",
      "linkedin": "LinkedIn",
      "xing": "Xing",
      "github": "GitHub",
      "noDocument": "Keine Dokumente verfügbar",
      "applicationPackage": "Bewerbungsunterlagen",
      "coverLetter": "Anschreiben",
      "grades": "Noten",
      "referenceLetter": "Arbeitszeugnis"
    },
    "informationTab": {
      "segmentTitle": "Information",
      "dateOfBirth": "Geburtsdatum",
      "university": "Hochschule",
      "highestDegree": "Höchster Abschluss",
      "travelWillingness": "Reisebereitsschaft",
      "relocationWillingness": "Umzugsbereitschaft",
      "languageSkills": "Sprachkenntnisse (mindestens fließend)",
      "salaryExpectation": "Gehaltserwartung",
      "remoteWork": "Homeoffice Tage"
    },
    "evaluationTab": {
      "segmentTitle": "Beurteilung",
      "projectExperience": "Projekterfahrung",
      "personalGoal": "Persöhnliche Ziele",
      "changeMotivation": "Wechselmotivation",
      "answerToKOQuestions": "Antwort auf K.O. Fragen",
      "noData": "Keine Daten verfügbar"
    },
    "notFound": "Kandidat nicht gefunden oder nicht authentifiziert zu sehen",
    "rejection": {
      "Academic background does not fit": "Akademischer Hintergrund passt nicht",
      "Already talked to customer": "War bereits beim Kunden im Gespräch",
      "Company not attractive": "Unternehmen nicht attraktiv",
      "Currently no motivation for change": "Aktuell keine Wechselmotivation",
      "Different salary expectation": "Gehaltsvorstellung zu stark abweichend",
      "Insufficient language skills": "Sprachkenntnisse nicht ausreichend",
      "Lack of mobility": "Mobilität",
      "No reason given": "Begründung fehlt",
      "Only available part-time": "Nur als Teilzeit verfügbar",
      "Other contract offer accepted": "Anderes Vertragsangebot angenommen",
      "Personality does not fit the profile": "Persönlichkeit passt nicht zum Profil",
      "Position filled": "Position wurde besetzt",
      "Position unattractive": "Position nicht attraktiv",
      "Remains with current employer": "Bleibt beim aktuellen Arbeitgeber",
      "Wrong job level": "Profil passt vom Level nicht",
      "Wrong skillset": "Profil passt fachlich nicht",
      "Only interested in freelance positions": "Nur an Freelance Rollen interessiert"
    }
  },
  "login": {
    "usernamePlaceholder": "Benutzername",
    "passwordPlaceholer": "Passwort",
    "loginButton": "Anmelden",
    "logoutButton": "Abmelden",
    "forgotPassword": "Passwort vergessen?",
    "organizationPlaceholder": "Organisationsschlüssel",
    "welcomeMessage": "Willkommen, melde dich an.",
    "requestPasswordReset": "E-Mail senden",
    "backButton": "Zurück",
    "explainOrgId": "Was ist der Organisationsschlüssel?",
    "passwordResetConfirm": "Neues Passwort Bestätigen",
    "passwordReset": "Neues Passwort",
    "confirmNewPassword": "Bestätigen",
    "cancle": "Abbrechen",
    "resetPassword": "Passwort zurücksetzen",
    "explainReset": "Trage hier dein neues Passwort ein und bestätige dieses in dem du es noch einmal eingibst.",
    "explainOrgIdDescription": "Der Organizationsschlüssel wird verwendet um deine Umgebung einzurichten. Du erhältst diesen Schlüssel von deinem Administrator. Wenn du weitere Hilfe brauchst, kontaktiere uns unter",
    "resetSuccess": "Passwort-Reset erfolgreich",
    "forgotSuccess": "Reset-PIN gesendet"
  },
  "search": {
    "searchPlaceholder": "Suche...",
    "cancel": "Abbrechen",
    "projects": "Projekte",
    "candidates": "Kandidat:innen",
    "projectsNotFound": "Keine Projekte gefunden",
    "candidatesNotFound": "Keine Kandidat:innen gefunden"
  },
  "breadcrumbs": {
    "projects": "Projekte",
    "candidates": "Kandidat:innen"
  },
  "progressBar": {
    "project": {
      "contact": "Briefing",
      "prequalification": "Auswahlprozess",
      "evaluation": "Auswahlprozess",
      "presentation": "Profilvorstellung",
      "firstInterview": "Interviewphase",
      "contract": "Vertragsverhandlungen",
      "placement": "Platzierung",
      "followupInterview": "Interviewphase"
    },
    "candidate": {
      "presentation": "Profilübermittlung",
      "firstInterview": "Erstes Interview",
      "followUpInterviews": "Folgeinterview(s)",
      "contractNegotiations": "Vertragsverhandlungen",
      "placement": "Platzierung"
    }
  },
  "notifications": {
    "title": "Benachrichtigungen",
    "read": "Bereits gelesen",
    "unRead": "Neue Benachrichtigungen",
    "NoUnRead": "Keine neuen Benachrichtigungen",
    "markAllAsRead": "Alle als gelesen markieren",
    "newMessage": "Eine neue Nachricht",
    "newProject": "Das Projekt wurde angelegt",
    "newCandidate": "Kandidat:in wurde hinzugefügt",
    "justNow": "Gerade eben",
    "minutesAgo": "Vor einer Minute | Vor {timeAgo} Minuten",
    "hoursAgo": "Vor einer Stunde | Vor {timeAgo} Stunden",
    "daysAgo": "Vor einem Tag | Vor {timeAgo} Tagen",
    "monthAgo": "Vor einem Monat | Vor {timeAgo} Monaten",
    "yearsAgo": "Vor einem Jahr | Vor {timeAgo} Jahren"
  },
  "sidebar": {
    "home": "Home",
    "projects": "Projekte",
    "projectsOverview": "Projektübersicht",
    "candidates": "Kandidat:innen",
    "emmybi": {
      "title": "EmmyBI",
      "current": "Aktuell",
      "history": "Verlauf",
      "targetCompanies": "Zielfirmen"
    },
    "emmyTalk": "EmmyTalk",
    "allProjects": "Zeige alle Projekte..."
  },
  "header": {
    "account": {
      "title": "Account"
    }
  },
  "languages": {
    "African": "Afrikaans",
    "Albanian": "Albanisch",
    "Amharic": "Amharisch",
    "Arabic": "Arabisch",
    "Armenian": "Armenisch",
    "Azerbaijani": "Aserbaidschanisch",
    "Belarusian": "Weißrussisch",
    "Bengali": "Bengalisch",
    "Bhutanese": "Bhutanisch",
    "Bislama": "Bislama",
    "Bosnian": "Bosnisch",
    "Bulgarian": "Bulgarisch",
    "Burmese": "Bulgarisch",
    "Cambodian": "Kambodscha",
    "Catalan": "Katalanisch",
    "Chinese": "Chinesisch",
    "Croatian": "Kroatisch",
    "Czech": "Tschechisch",
    "Danish": "Dänisch",
    "Dari": "Dari",
    "Dutch": "Niederländisch",
    "English": "Englisch",
    "Estonian": "Estnisch",
    "Filipino": "Filipino",
    "Finnish": "Finnisch",
    "French": "Französisch",
    "Georgian": "Georgisch",
    "German": "Deutsch",
    "Greek": "Griechisch",
    "Hebrew": "Hebräisch",
    "Hindi": "Hindi",
    "Hungarian": "Ungarisch",
    "Icelandic": "Isländisch",
    "Indonesian": "Isländisch",
    "Irish": "Irisch",
    "Italian": "Italienisch",
    "Japanese": "Japanisch",
    "Kazakh": "Kasachstan",
    "Korean": "Koreanisch",
    "Kyrgyz": "Kirgisisch",
    "Lao": "Laos",
    "Latin": "Lateinisch",
    "Latvian": "Lettisch",
    "Lithuanian": "Litauisch",
    "Macedonian": "Mazedonisch",
    "Madagassisch": "Madagassisch",
    "Malagasy": "Madagassisch",
    "Maldivian": "Maledivisch",
    "Maltese": "Malteser",
    "Mongolian": "Mongolisch",
    "Nauruan": "Nauruisch",
    "Nepalese": "Nepalesisch",
    "Norwegian": "Norwegisch",
    "Persian": "Persisch",
    "Polish": "Polnisch",
    "Portuguese": "Portugiesisch",
    "Romanian": "Rumänisch",
    "Russian": "Russisch",
    "Samoan": "Samoanisch",
    "Serbian": "Serbisch",
    "Sinhala": "Singhalesisch",
    "Slovak": "Slowakisch",
    "Slovenian": "Slowenisch",
    "Somali": "Somali",
    "Spanish": "Spanisch",
    "Swahili": "Swahili",
    "Swedish": "Schwedisch",
    "Tadschikisch": "Tadschikisch",
    "Tajik": "Tadschikisch",
    "Thai": "Thailändisch",
    "Turkish": "Türkisch",
    "Turkmen": "Turkmen",
    "Ukrainian": "Ukrainisch",
    "Urdu": "Urdu",
    "Uzbek": "Usbekisch",
    "Vietnamese": "Vietnamesisch"
  }
}
