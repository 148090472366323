<script lang="ts" setup>
import { PropType } from 'vue'

import Tooltip from './Tooltip.vue'
import ResponsiveSlot from './ResponsiveSlot.vue'

import { Step } from '@/models/Step'
import { Status } from '@/models/Status'
import { getLabelFromLocalizedString } from '@/lib/getLabelFromLocalizedString'

const { locale } = useI18n()

const breakpoints = ref([
  { name: 'mobile', range: { max: 1700 } },
  { name: 'desktop', range: { min: 1701 } }
])

interface PathItem {
  state: 'pre' | 'current' | 'post'
  status: Status
  timestampText?: string
}

// =======
// PROPS
// =======

const props = defineProps({
  /**
   * Holds either an array of the steps that a single candidate has gone through or a string of the name of the current,
   * active step.
   *
   * The difference being that one will display a bar with timestamps and the other will display a bar without
   * timestamps.
   *
   * If providing a `string` the `statuses` prop must contain the status with the name provided.
   */
  progression: {
    type: [Array, String] as PropType<Step[] | string>,
    required: true
  },

  statuses: {
    type: Array as PropType<Status[]>,
    required: true
  },

  windowBased: {
    type: Boolean,
    default: false
  },

  displayType: {
    type: String as PropType<'public' | 'pathGroup' | 'all'>,
    default: 'all'
  }
})

// =======
// LIFECYCLE
// =======

onBeforeMount(() => {
  if (typeof props.progression === 'string') {
    validateProgressionName(props.progression, props.statuses)
  }
})

// =======
// COMPUTED
// =======

/**
 * A sorted array of the provided statuses. The statuses are sorted based on their `.rank`.
 */
const sortedStatuses = computed(() => {
  let statuses = props.statuses

  statuses = statuses.filter(status => status.fieldSet.jobApplication !== 'emmy__e_Status_Rejection')

  if (props.displayType === 'pathGroup') {
    const pathGroups = Array.from(new Set(statuses.map(status => status.pathGroup)))

    return pathGroups.map((name, index) => {
      const status = statuses.find(status => status.pathGroup === name)
      return {
        ...status,
        rank: index
      } as Status
    })
  } else {
    const all = props.statuses.toSorted((a, b) => a.rank - b.rank)

    if (props.displayType === 'public') {
      return all.filter(status => status.isPresented)
    } else {
      return all
    }
  }
})

/**
 * A computed array with the different steps that a candidate or project has gone through. A `PathItem` is an object
 * that contains the `state` of the step, the `status` of the step and an optional `timestampText` that will be
 * displayed in the tooltip.
 */
const items = computed(() => {
  var temp: PathItem[] = []

  // ARRAY
  if (isArray.value) {
    // This will filter all the progressions based on the provided statuses and sort them based on the rank of the
    // statuses.
    const progressions: Step[] = sortedStatuses.value
      .map(status => {
        return (props.progression as Step[]).find(step => step.status.name === status.name)
      })
      .filter(step => step !== undefined)

    const currentStatusIndex = sortedStatuses.value.findIndex(
      status => status.name === progressions[progressions.length - 1].status.name
    )

    // PRE
    for (let i = 0; i < currentStatusIndex; i++) {
      const status = sortedStatuses.value[i]
      const step = progressions.find(step => step.status.name === status.name)

      temp.push({
        state: 'pre',
        status: status,
        timestampText: step && generateTimestampString(step)
      })
    }

    // CURRENT
    const currentStatus = sortedStatuses.value[currentStatusIndex]
    const currentStep = progressions.find(step => step.status.name === currentStatus.name)

    temp.push({
      state: 'current',
      status: currentStatus,
      timestampText: currentStep && generateTimestampString(currentStep)
    })

    // POST
    for (let i = currentStatusIndex + 1; i < sortedStatuses.value.length; i++) {
      const status = sortedStatuses.value[i]

      temp.push({
        state: 'post',
        status: status
      })
    }
  }

  // STRING
  else {
    const index = sortedStatuses.value.findIndex(status => status.name === props.progression)

    if (index === -1) {
      throw new Error(
        `Invalid step name "${props.progression}". Valid steps are: ${sortedStatuses.value
          .map(status => status.name)
          .join(', ')}`
      )
    }

    // PRE
    for (let i = 0; i < index; i++) {
      const status = sortedStatuses.value[i]

      temp.push({
        state: 'pre',
        status: status
      })
    }

    // CURRENT
    const currentStatus = sortedStatuses.value[index]

    temp.push({
      state: 'current',
      status: currentStatus
    })

    // POST
    for (let i = index + 1; i < sortedStatuses.value.length; i++) {
      const status = sortedStatuses.value[i]

      temp.push({
        state: 'post',
        status: status
      })
    }
  }

  return temp
})

/**
 * Denotes wether the StatusBar is displaying a bar with timestamps or a general bar without timestamps and just the
 * different step names.
 */
const isArray = computed(() => {
  return Array.isArray(props.progression)
})

const preItems = computed(() => {
  return items.value.filter(item => item.state === 'pre')
})

const currentItem = computed(() => {
  return items.value.filter(item => item.state === 'current')[0]
})

const postItems = computed(() => {
  return items.value.filter(item => item.state === 'post')
})

// =======
// METHODS
// =======

/**
 * Validates that the provided progression is a valid step name. If the progression is not a valid step name an error
 * will be thrown.
 */
function validateProgressionName(progression: string, statuses: Status[]) {
  const statusNames = statuses.map(status => status.name)
  if (!statusNames.includes(progression)) {
    throw new Error(`Invalid step name "${progression}". Valid steps are: ${statusNames.join(', ')}`)
  }
}

/**
 * Generates a string for the timestamp passed to it. If the provided progression is a `string` it will return an empty
 * string. If the progression is an array it will return the timestamp of the step at the given index.
 */
function generateTimestampString(step: Step) {
  const timestamp = step.timestamp
  if (!timestamp) return undefined

  return new Date(timestamp).toLocaleString(locale.value, {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })
}

function localizeStatus(status: Status) {
  return getLabelFromLocalizedString(status.nameLocalized, locale.value, 'en') ?? status.name
}
</script>

<template>
  <ResponsiveSlot :breakpoints="breakpoints" :window-based="windowBased">
    <template #mobile>
      <div class="path">
        <div v-if="preItems.length !== 0" class="path-element pre">
          <div v-for="item in preItems" :key="item.status.id">
            <Tooltip
              position="right"
              :text="localizeStatus(item.status) + (item.timestampText ? ' - ' + item.timestampText : '')"
            >
              <div class="dot" />
            </Tooltip>
          </div>
        </div>

        <div class="path-element current">
          <div class="vstack">
            <p class="step-name">
              {{ localizeStatus(currentItem.status) }}
            </p>
            <p v-if="currentItem.timestampText" class="date">
              {{ currentItem.timestampText }}
            </p>
          </div>
        </div>

        <div v-if="postItems.length !== 0" class="path-element post">
          <div v-for="item in postItems" :key="item.status.id">
            <Tooltip position="left" :text="localizeStatus(item.status)">
              <div class="dot" />
            </Tooltip>
          </div>
        </div>
      </div>
    </template>

    <template #desktop>
      <div class="path desktop">
        <div
          v-for="(item, index) in items"
          :key="item.status.id"
          class="path-element desktop"
          :class="{
            pre: item.state === 'pre',
            current: item.state === 'current',
            post: item.state === 'post'
          }"
          :style="{ zIndex: statuses.length - index }"
        >
          <div class="vstack">
            <p class="step-name desktop">{{ localizeStatus(item.status) }}</p>
            <p v-if="item.timestampText" class="date">
              {{ item.timestampText }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </ResponsiveSlot>
</template>

<style lang="scss" scoped>
@import '@/theme/globals.scss';

$heightMobile: 28px;
$heightDesktop: 34px;

.vstack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  z-index: 0;
}

.path {
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 0 1px #ededf0;
  background-color: #fff;
  cursor: default;
  margin-bottom: 1px; // Prevents the shadow from being cut off

  height: $heightMobile;
  border-radius: calc($heightMobile / 2);

  &.desktop {
    justify-content: space-between;

    height: $heightDesktop;
    border-radius: calc($heightDesktop / 2);
  }
}

.path-element {
  display: flex;
  align-items: center;
  justify-content: center;

  height: $heightMobile;
  padding: 0 calc($heightMobile / 3);
  border-radius: calc($heightMobile / 2);

  &:first-child {
    /* Affects .current */
    margin-left: 0;
  }

  &.desktop {
    width: 100%;
    text-align: center;

    height: $heightDesktop;
    padding: 0 calc($heightDesktop / 3 + 18px);
    border-radius: calc($heightDesktop / 2);

    box-shadow: 0 0 0 2px #ededf0;

    // border: 1px solid blueviolet;

    &:not(:first-of-type) {
      margin-left: calc(-1 * $heightDesktop);
      padding-left: calc($heightDesktop + 20px);

      // border: 1px solid salmon;
    }
  }
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 2px;
  transition: all ease-in-out 300ms;

  &:hover {
    background-color: var(--status-current-background-color);
  }
}

.pre {
  background-color: var(--status-past-background-color);
  z-index: 3;

  & p {
    color: var(--status-past-text-color);
  }
}

.current {
  font-size: small;
  color: var(--status-current-text-color);
  z-index: 2;
  flex: 1;
  background-color: var(--status-current-background-color);
  flex-shrink: 2;

  margin-left: calc(-1 * $heightMobile);

  &.desktop {
    flex: auto;
  }
}

.post {
  z-index: 2;
  background-color: #fff;

  & p {
    color: var(--font-secondary);
  }

  & .dot:not(:hover) {
    background-color: #ccc;
  }
}

p {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  text-overflow: clip;
  text-align: center;

  line-height: calc(($heightMobile - 4px) / 2);

  &.desktop {
    line-height: calc(($heightDesktop - 4px) / 3);
  }
}

.step-name {
  font-weight: medium;

  &.desktop {
    font-weight: 600;
    font-size: 14px;
  }
}

.date {
  margin-top: 2px;
  font-size: x-small;
}
</style>
